"use client";
import React from "react";
import styled from "styled-components";
import ReactHtmlParser from "react-html-parser";
import { text, title } from "@/styles/globalStyleVars";

const Title = ({
  text,
  fontSize,
  fontWeight,
  color,
  letterSpacing,
  lineHeight,
  textTransform,
  margin,
  padding,
  borderColor,
  varient,
  center,
  classname,
  marginSm,
  width,
  number,
  version,
  background,line
}) => {
  return (
    <StyledTitle
      className={`title ${classname}`}
      fontSize={fontSize}
      fontWeight={fontWeight}
      color={color}
      lineHeight={lineHeight}
      LetterSpacing={letterSpacing}
      textTransform={textTransform}
      margin={margin}
      padding={padding}
      varient={varient}
      center={center}
      marginSm={marginSm}
      width={width}
      borderColor={borderColor}
      version={version}
      line={line}
      background={background}
    >
      {number && <p>{number}.</p>}

      {text && <h2 className={"split-left"}>{ReactHtmlParser(text)} </h2>}
    </StyledTitle>
  );
};

const StyledTitle = styled.div`
  margin: ${(props) => props.margin || "0px"};
  position: relative;
  // width: ${(props) => props.width || "fit-content"};
  font-family: ${title};
  text-align: ${(props) => (props?.center ? "center" : "")};
  padding: ${(p) => p.padding};
  z-index: 6;
  width: 100%;
  //padding-left: 18px;

  &:after {
    position: absolute;
    content: "";
    background-color: ${(p) => p.background || "#8C263D"};
    opacity: 0.2;
    height: 350px;
    width: 70px;
    bottom: -110px;
    left: -30px;
    z-index: -1;
  }


  ${({ version }) =>
    version &&
    `
        display:flex;
        align-items:center;
    `}
  p {
    font-size: 35px;
    line-height: 45px;
    font-family: ${title};
    color: ${(props) => props.color || text};
    position: relative;
    text-transform: uppercase;
    margin-bottom: 0;
    overflow: hidden;

    &:after {
      width: 70%;
      height: 1px;
      background-color: ${(props) => props.color || text};
      content: "";
      position: absolute;
      right: 0;
      bottom: 30px;
    }

    @media (min-width: 769px) {
      display: none;
      ${({ version }) =>
        version &&
        `
                    display:block;
                `}
      &:after {
        ${({ version }) =>
          version &&
          `
                    display:none;
                `}
      }
    }
    @media(max-width: 767px){
      &:after{
        display: none;
      }
    }
  }

  h2 {
    font-size: ${(props) => props.fontSize || 40}px;
    line-height: ${(props) => props.lineHeight || 48}px;
    //text-transform: uppercase;
    font-weight: ${(props) => props.fontWeight || "600"};
    color: ${(props) => props.color || text};
    text-transform: inherit;
    position: relative;
    //padding-left: 10px;
    flex-grow: 1;
    font-family: Butler;

    ${({ version, props }) =>
      version &&
      `
            padding-left:30px;  
           padding-top: 20px;
        `}
    &:before {
      content: "";
      position: absolute;
      width: 100%;
      top: 0;
      height: 1px;
      background-color: ${(props) => props.color || text};
      left: 30px;
      display: none;
      ${({ version, props }) =>
        version &&
        `
                display:block    
             `}
    }
  }

  @media (max-width: 767px) {
    padding-left: 6px;
    display: flex ;
    h2 {
      font-size: 36px !important;
      line-height: 40px !important;
    }

    h2 {
      padding-left: 0;
      padding-top: 0;

      &:before {
        display: none;
      }
    }
  }
`;

export default Title;
