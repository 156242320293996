"use client";
import styled from "styled-components";
import Title from "@/components/Title";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Button from "@/components/Button";
import Link from "next/link";
import { useEffect, useState } from "react";
import axios from "axios";

const ContactForm = ({ data }) => {
  const [isSubmitting, setIsSubmitting] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsSubmitting(false);
    }, 300);
  }, []);

  const { register, handleSubmit, formState, reset } = useForm({ mode: "all" });
  const { errors, isSubmitSuccessful } = formState;

  //--- form submit

  const success = (msg) =>
    toast.success(msg, {
      position: "top-right",
      autoClose: 4000,
      closeOnClick: true,
      progress: undefined,
    });

  const error = (msg) =>
    toast.error(msg, {
      position: "top-right",
      autoClose: 4000,
      closeOnClick: true,
      progress: undefined,
    });

  const onSubmit = async (data) => {
    setIsSubmitting(true); // Show spinner
    try {
      const formData = new FormData();
      formData.append("_wpcf7_unit_tag", "form_" + "5522");
      formData.append("your-name", data?.name);
      formData.append("email", data?.email);
      formData.append("phone", data?.phone);
      formData.append("message", data?.message);

      const response = await axios.post(
        "https://cms.ucbstock.com.bd//wp-json/contact-form-7/v1/contact-forms/5522/feedback",
        formData
      );

      if (response.status === 200) {
        success(response?.data?.message);

        reset(); // Reset form fields
      } else {
        error("Failed to submit form. Please try again later.");
      }
    } catch (err) {
      error(
        err?.message
          ? err?.message
          : "Failed to submit form. Please try again later."
      );
    } finally {
      setIsSubmitting(false); // Hide spinner
    }
  };

  const onError = (errors) => {
    // setToastShown(false); // Reset toast shown state on new submission attempt
    const firstError = Object.values(errors)[0];
    error(firstError.message);
  };

  return (
    <StyledContactForm
      className={"horizontal-scroll"}
      data-speed-right={30}
      data-speed-left={-30}
    >
      <Container>
        <Row>
          <Col md={4}>
            <ul>
              <li>
                <Link
                  href={
                    "https://www.google.com/maps/place/United+Commercial+Bank+%7C+Head+Office/@23.7874374,90.4139519,17z/data=!3m1!4b1!4m6!3m5!1s0x3755c7a1ee5193d9:0xc4a50604869e47f5!8m2!3d23.7874374!4d90.4165268!16s%2Fg%2F1thq86np?sca_esv=e4b6c45cd17b4d13&sca_upv=1&rlz=1C1ONGR_enBD1089BD1090&output=search&q=UCBL,+Bulus+Centre,+Gulshan+Avenue,+Dhaka&source=lnms&fbs=AEQNm0Aa4sjWe7Rqy32pFwRj0UkW1DRbm01j6DCVS0r1sTxn7oKTGqI4IKdl-uwVoUv_nV3S07eEReNDnqGxeFIt5XEnXuVE2K_kZcfo858YoXmS0TjPJy3v6Gy88h03iallEurBDnpUfXlH9bwwGBaHbyLatFaqhYjfrLJXFgIuyuS7tniK4VM7X-nDhQ2tImKyEo8ek6Ak&ved=1t:200715&ictx=111&entry=tts&g_ep=EgoyMDI0MDYxOS4xKgBIAVAD"
                  }
                  target={"_blank"}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                  >
                    <g
                      id="Mask_Group_19972"
                      data-name="Mask Group 19972"
                      transform="translate(-798 -4283)"
                    >
                      <rect
                        id="Rectangle_6974"
                        data-name="Rectangle 6974"
                        width="20"
                        height="20"
                        transform="translate(798 4283)"
                        fill="#fff"
                        stroke="#fff"
                        strokeWidth="1"
                      />
                      <path
                        id="Path_10815"
                        data-name="Path 10815"
                        d="M9.86,0A6.893,6.893,0,0,0,3,6.909c0,5.414,6.215,11,6.48,11.239a.573.573,0,0,0,.761,0c.264-.236,6.48-5.826,6.48-11.24A6.893,6.893,0,0,0,9.86,0Zm0,10.671A3.811,3.811,0,1,1,13.671,6.86,3.815,3.815,0,0,1,9.86,10.671Z"
                        transform="translate(798.14 4283.854)"
                        fill="#e62e43"
                      />
                    </g>
                  </svg>

                  <p>
                    Bulus Center, <br />
                    Plot-CWS-(A)-1, Road No 34, <br />
                    Gulshan Avenue 1212 <br />
                    Dhaka, Bangladesh
                  </p>
                </Link>
              </li>
              <li>
                <Link href={"tel:01324243200"}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                  >
                    <g
                      id="Mask_Group_19930"
                      data-name="Mask Group 19930"
                      transform="translate(-798 -4283)"
                    >
                      <rect
                        id="Rectangle_6950"
                        data-name="Rectangle 6950"
                        width="20"
                        height="20"
                        transform="translate(798 4283)"
                        fill="#fff"
                        stroke="#fff"
                        strokeWidth="1"
                      />
                      <g
                        id="Group_25463"
                        data-name="Group 25463"
                        transform="translate(799.09 4284.375)"
                      >
                        <path
                          id="Path_10809"
                          data-name="Path 10809"
                          d="M15.148,12.006c-1.042-.891-2.1-1.431-3.129-.541L11.4,12c-.45.39-1.286,2.215-4.518-1.5S5.577,6.207,6.028,5.82l.618-.539c1.024-.892.638-2.015-.1-3.171L6.1,1.41C5.357.257,4.549-.5,3.523.39L2.968.875A5.207,5.207,0,0,0,.939,4.321c-.37,2.449.8,5.254,3.474,8.331s5.289,4.625,7.768,4.6a5.221,5.221,0,0,0,3.693-1.529l.557-.485c1.024-.89.388-1.8-.655-2.689Z"
                          transform="translate(0)"
                          fill="#e62e43"
                        />
                      </g>
                    </g>
                  </svg>

                  <p>+(880) 1324243200</p>
                </Link>
              </li>
              <li>
                <Link href={"mailto:info@ucbstock.com.bd"}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17.781"
                    height="13.316"
                    viewBox="0 0 17.781 13.316"
                  >
                    <g
                      id="Group_25464"
                      data-name="Group 25464"
                      transform="translate(0 -64.266)"
                    >
                      <path
                        id="Path_10811"
                        data-name="Path 10811"
                        d="M10.376,176.343a2.674,2.674,0,0,1-2.972,0L.118,171.486q-.061-.04-.118-.084v7.959A1.636,1.636,0,0,0,1.637,181H16.144a1.636,1.636,0,0,0,1.637-1.637V171.4c-.039.029-.078.057-.119.084Z"
                        transform="translate(0 -103.415)"
                        fill="#e62e43"
                      />
                      <path
                        id="Path_10812"
                        data-name="Path 10812"
                        d="M.7,67.2l7.286,4.857a1.633,1.633,0,0,0,1.816,0L17.084,67.2a1.56,1.56,0,0,0,.7-1.3,1.638,1.638,0,0,0-1.636-1.636H1.636A1.638,1.638,0,0,0,0,65.9a1.56,1.56,0,0,0,.7,1.3Z"
                        transform="translate(0)"
                        fill="#e62e43"
                      />
                    </g>
                  </svg>

                  <p>info@ucbstock.com.bd</p>
                </Link>
              </li>
            </ul>
          </Col>
          <Col md={8}>
            <Form className={"form"} onSubmit={handleSubmit(onSubmit, onError)}>
              <div className="form-group">
                <Form.Group controlId="formBasicEmail">
                  <Form.Control
                    className={
                      formState?.errors?.name?.message
                        ? "has-error form-control-lg"
                        : "form-control-lg"
                    }
                    {...register("name", {
                      required: "Enter your full name",
                      pattern: {
                        value: /^[A-Za-z ]+$/,
                        message: "Name must contain only letters",
                      },
                    })}
                    type="text"
                    placeholder="Name*"
                  />
                  {errors.name && (
                    <span className="error-message form-error">
                      {errors.name.message}
                    </span>
                  )}
                </Form.Group>
              </div>
              <div className="phoneEmail">
                <div className="form-group">
                  <Form.Group controlId="formBasicEmail">
                    <Form.Control
                      className={
                        formState?.errors?.email?.message
                          ? "has-error form-control-lg"
                          : "form-control-lg"
                      }
                      {...register("email", {
                        required: {
                          value: true,
                          message: "Enter a valid email address",
                        },
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: "Enter a valid email address",
                        },
                      })}
                      type="email"
                      placeholder="Email*"
                    />
                    {errors.email && (
                      <span className="error-message form-error">
                        {errors.email.message}
                      </span>
                    )}
                  </Form.Group>
                </div>
                <div className="form-group">
                  <Form.Group controlId="formBasicPhone">
                    <Form.Control
                      className={
                        formState?.errors?.phone?.message
                          ? "has-error form-control-lg"
                          : "form-control-lg"
                      }
                      {...register("phone", {
                        required: {
                          value: true,
                          message: "Enter your phone number",
                        },
                        pattern: {
                          value: /^01[0-9]{9}$/,
                          message: "Enter a valid 11 digit phone number",
                        },
                      })}
                      type="number"
                      placeholder="Phone Number*"
                    />
                    {errors.phone && (
                      <span className="error-message form-error">
                        {errors.phone.message}
                      </span>
                    )}
                  </Form.Group>
                </div>
              </div>
              <div className="form-group">
                <Form.Group controlId="formBasicPhone">
                  <Form.Control
                    as="textarea"
                    rows={4}
                    className={
                      formState?.errors?.message?.message
                        ? "has-error form-control-lg"
                        : "form-control-lg"
                    }
                    {...register("message", {
                      required: {
                        value: true,
                        message: "Enter your Message",
                      },
                    })}
                    // type="textarea"
                    placeholder="Message*"
                  />
                  {errors.message && (
                    <span className={"form-error error-message"}>
                      {errors.message?.message}
                    </span>
                  )}
                </Form.Group>
              </div>
              <div className={"form-group width-fit"}>
                <div onClick={handleSubmit(onSubmit, onError)}>
                  <Button src={"javascript:void(0)"} text={"Leave us a Message"} />
                </div>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
    </StyledContactForm>
  );
};

const StyledContactForm = styled.section`
  background-color: #fff;
  position: relative;

  .form-group {
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }

    .form-control {
      box-shadow: none;
      outline: 0;
      border-radius: 0;
      background: transparent;
      height: auto;
      border-left: none;
      border-right: none;
      border-top: none;
      border-color: rgba(198, 198, 198, 0.5);
      padding: 0 0 8px;
      color: #3c3c3b;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;

      &:focus {
        border-color: #3c3c3b;
      }

      &::placeholder {
        color: #c6c6c6;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
      }
    }

    textarea {
      min-height: 80px;
    }
  }

  .phoneEmail {
    display: flex;
    gap: 25px;

    .form-group {
      flex: 0 0 49%;
    }

    @media (max-width: 767px) {
      flex-direction: column;
      gap: 0px;
      .form-group {
        flex: 0 0 100%;

        &:last-child {
          margin-bottom: 30px;
        }
      }
    }
  }

  ul {
    display: flex;
    flex-direction: column;

    li {
      margin-bottom: 40px;

      &:last-child {
        margin-bottom: 0;
      }

      a {
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        color: #191919 !important;
        display: flex;

        svg {
          margin-right: 20px;
          position: relative;
          top: 4px;
        }

        p {
          font-size: 16px;
          font-weight: 500;
          line-height: 20px;
          color: #191919 !important;
        }

        &:hover {
          color: #e62e43 !important;

          p {
            color: #e62e43 !important;
          }
        }
      }
    }
  }

  .container {
    margin: 0;
  }

  @media (max-width: 992px) {
    .col-md-4,
    .col-md-8 {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  @media (max-width: 767px) {
    padding-bottom: 60px;
    .bg-text-one,
    .bg-text-two {
      display: none;
    }
  }

  form {
    @media (max-width: 767px) {
      margin-bottom: 40px;
    }
  }
`;

export default ContactForm;
