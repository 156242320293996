"use client";
import { SplitText } from "gsap/SplitText";
import { useEffect } from "react";
import { gsap } from "gsap";
import { usePathname } from "next/navigation";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export const splitLeft = (dependency) => {
  const pathName = usePathname();
  gsap.registerPlugin(SplitText, ScrollTrigger);
  useGSAP(() => {
    ScrollTrigger.refresh();
    const ctx = gsap.context(() => {
      gsap.utils.toArray(".split-left").forEach((item, i) => {
        const splitText = new SplitText(item, {
          type: "chars,words,lines",
          reduceWhiteSpace: true,
        });
        const chars = splitText.chars;

        gsap.from(chars, 1.5, {
          x: "10vw",
          // opacity: 0,
          // ease: "elastic.out(1, 0.3)",
          stagger: 0.015,
          scale: 0.95,
          // delay: 0.2,
          ease: "expo.out",
          scrollTrigger: {
            trigger: item,
            toggleActions: "restart none none reset",
          },
        });
        gsap.from(chars, 0.6, {
          // x: 80,
          opacity: 0,
          // ease: "elastic.out(1, 0.3)",
          stagger: 0.016,
          // delay: 0.2,
          ease: "power4.out",
          scrollTrigger: {
            trigger: item,
            toggleActions: "restart none none reset",
          },
        });
      });
    });
    return () => ctx.revert();
  }, [pathName, dependency]);
};

export const ParallaxX = (dependency) => {
  const pathName = usePathname();
  useGSAP(() => {
    ScrollTrigger.refresh();
    const ctx = gsap.context(() => {
      gsap.utils.toArray(".parallax-x").forEach((item, i) => {
        let parallaxSize = item.getAttribute("data-size");
        gsap.to(item, {
          xPercent: parallaxSize || -40,
          ease: "none",
          scrollTrigger: {
            // start: "clump(bottom 0)",
            trigger: item,
            // markers: true,
            scrub: true,
          },
        });
      });
    });
    return () => ctx.revert();
  }, [pathName, dependency]);
};

export const ParallaxXR = (dependency) => {
  const pathName = usePathname();
  ScrollTrigger.refresh();
  useGSAP(() => {
    const ctx = gsap.context(() => {
      gsap.utils.toArray(".parallax-xr").forEach((item, i) => {
        let parallaxSize = item.getAttribute("data-size");
        gsap.to(item, {
          xPercent: parallaxSize || 30,
          ease: "none",
          scrollTrigger: {
            // start: "clump(bottom 0)",
            trigger: item,
            // markers: true,
            scrub: true,
          },
        });
      });
    });
    return () => ctx.revert();
  }, [pathName, dependency]);
};

export const FadeUp = (dependency) => {
  const pathName = usePathname();
  ScrollTrigger.refresh();
  useGSAP(() => {
    const ctx = gsap.context(() => {
      gsap.utils.toArray(".fade-up").forEach((item, i) => {
        gsap.from(item, {
          y: "80px",
          opacity: "0",
          ease: "expo.out",
          duration: 1.5,
          // delay: 0.05,
          scrollTrigger: {
            // start: "bottom 0",
            trigger: item,
            toggleActions: "play none none reverse",

            preventOverlaps: true,
            fastScrollEnd: true,
          },
        });
      });
    });
    return () => ctx.revert();
  }, [pathName, dependency]);
};
